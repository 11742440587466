import { filter } from 'lodash';
import { useState } from 'react';
import './Form1.css';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Modal,
  Box,
} from '@mui/material';
// components
import { useGetMineQuery, useDeleteMineMutation } from '../services/api';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import Error from '../components/error/Error';
import Success from '../components/success/Success';

// ----------------------------------------------------------------------

const TABLE_HEAD1 = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'number', label: 'S.NO', alignRight: false },
  { id: 'Mine Name', label: 'Mine Name', alignRight: false },
  { id: 'State', label: 'State', alignRight: false },
  { id: 'City', label: 'City', alignRight: false },
  { id: 'Area', label: 'Area', alignRight: false },
  { id: 'Minning area (Sq KM)', label: 'Minning area (Sq KM)', alignRight: false },
  { id: 'Production Capacity', label: 'Production Capacity', alignRight: false },
  { id: 'Mine license Number', label: 'Mine license Number', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.mineName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

export default function UserPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [editOpen, setEditOpen] = useState(null);
  const [id, setId] = useState(null);
  const [filterName, setFilterName] = useState('');
  const { data: mines } = useGetMineQuery();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteMine, { error, isSuccess }] = useDeleteMineMutation();
  console.log(mines, "mines");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = mines.data.map((n) => n.mineName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleOpenEditMenu = (id) => {
    navigate(`/dashboard/editmine?id=${id}`);
  };
  
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mines.data.length) : 0;

  const filteredMines = applySortFilter(mines.data, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredMines.length && !!filterName;

  const handleOpenMenu = (id) => {
    setId(id);
    setEditOpen(true);
    console.log(id);
  };

  const handleClose = () => {
    setEditOpen(null);
  };

  const handleDeleteConfirmation = async () => {
    await deleteMine(id);
    handleClose();
  };

  return (
    <>
      {error && <Error error={error} />}
      {isSuccess && <Success successMessage={'Deleted Successfully'} isSuccess={isSuccess} />}
      <Modal
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
            Are you sure you want to delete this Entry?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 30,
            }}
          >
            <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <Card style={{ marginTop: '2vmax' }}>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={mines.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Scrollbar>
          <TableContainer className="table-new">
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD1}
                rowCount={mines.data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredMines.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const { id, mineName, state, city, area, miningArea, productionCapacity, mineLicenseNumber } = row;
                  const selectedUser = selected.indexOf(mineName) !== -1;
                  const rowNum = page * rowsPerPage + index + 1;
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, mineName)} />
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={()=> handleOpenEditMenu(row?._id)}>
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                          Edit
                        </Button>
                        <Button sx={{ color: 'error.main' }} onClick={() => handleOpenMenu(row._id)}>
                          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                          Delete
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <b>{rowNum}</b>
                      </TableCell>
                      <TableCell align="left">{mineName} </TableCell>
                      <TableCell align="left">{state}</TableCell>
                      <TableCell align="left">{city}</TableCell>
                      <TableCell align="left">{area}</TableCell>
                      <TableCell align="left">{miningArea.area}</TableCell>
                      <TableCell align="left">{productionCapacity}</TableCell>
                      <TableCell align="left">{mineLicenseNumber}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 30 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
