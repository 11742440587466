import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, Collapse, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import '../../pages/Forms.css';
import navConfig from '../../layouts/dashboard/nav/config';

const NavItem = ({ item }) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);
  console.log(item, "item");
  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={item.path || '#'}
        onClick={item.submenus ? handleToggle : null}
        sx={{
          '&.active': {
            color: 'text.primary',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        {item.icon && <StyledNavItemIcon>{item.icon}</StyledNavItemIcon>}
        <ListItemText disableTypography primary={item.title} />
        {item.submenus && (
          <IconButton edge="end" size="small" onClick={handleToggle}>
            {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
      </StyledNavItem>

      {item.submenus && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.submenus.map((subItem, index) => (
              <NavItem key={index} item={subItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
};

const NavSection = ({ data = [], ...other }) => {
  const [menu, setMenu] = useState([]);
  const user = useSelector((state) => state.user);
  console.log(user, "user");
  console.log(data,"data");
  useEffect(() => {
    let updatedMenu = [];

    if (user?.data?.role === 'superAdmin') {
      updatedMenu = navConfig; // Show everything to superAdmin
    } else if (user?.data?.role === 'admin') {
      // Filter out 'Super Admin Masters' for admins
      updatedMenu = navConfig.filter((navItem) => navItem.title !== 'Super Admin Masters');
    } else {
      // Other roles: Filter menu based on roleList from user data
      const allowedRoles = data?.map((item) => item.value) || [];

      // Filter navConfig based on allowedRoles
      updatedMenu = navConfig.filter((navItem) => {
        if (allowedRoles.includes(navItem.title)) {
          // If the item has submenus, filter its submenus too
          if (navItem.submenus) {
            return {
              ...navItem,
              submenus: navItem.submenus.filter((sub) => allowedRoles.includes(sub.title)),
            };
          }
          return true;
        }
        return false;
      });
    }

    setMenu(updatedMenu);
  }, [user]);
  console.log(menu, "menu");
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {menu.length > 0 && menu.map((item, index) => <NavItem key={index} item={item} />)}
      </List>
    </Box>
  );
};

NavSection.propTypes = {
  data: PropTypes.array,
};

export default NavSection;

